<template>
  <div class="academic-years">
    <lenon-list-page
      title="Marker Settings"
      :columns="columns"
      :rows="markerSettings"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      search-placeholder="Search"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px !important;"
        >
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedTermId"
            placeholder="Select Term"
            :options="filteredTerms"
            label-name="name"
            value-name="ay_term_id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedClassId"
            placeholder="Select Class"
            :options="classes"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-button
            icon="SettingsIcon"
            label="Marker Setting"
            tool-tip-text="Manage Marker Settings(CRUD)"
            class=""
            @onClick="showMarkerSettingModal()"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-dropdown left>
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Marker Setting"
              @click="showMarkerSettingModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
      <template #total_questions="{row}">
        <b-badge variant="light-primary">
          {{ row.item.total_questions || 'Derived from Assessment' }}
        </b-badge>
      </template>
      <template #subject_id="{row}">
        <b-badge variant="light-success">
          {{ reducedClasses[+row.item.subject_id] }}
        </b-badge>
      </template>
      <template #page_type="{row}">
        <b-badge variant="light-success">
          {{ row.item.page_type.toString().toUpperCase() }}
        </b-badge>
      </template>
      <template #actions="{row}">
        <lenon-button
          icon="DownloadIcon"
          label="Answer Booklets"
          variant="flat-primary"
          tool-tip-text="Download answer booklets"
          @onClick="downloadSheet(row.item)"
        />
      </template>
    </lenon-list-page>

    <marker-setup
      :modal-opened="markerSettingModalOpened"
      @modalClosed="closeModal('markerSetting')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import MarkerSetup from '@/views/smartmarker/marker-settings/MarkerSetup.vue'
import { FETCH_FEE_SETTINGS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import {
  BBadge, BCard, BCol, BRow,
} from 'bootstrap-vue'
import logData from '@/libs/log'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import LenonSelect from '@/lenon/components/LenonSelect'
import confirm from '@/lenon/mixins/confirm'

export default {
  name: 'MarkerSettings',
  components: {
    LenonSelect,
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    MarkerSetup,
    LenonButton,
    BCard,
    BRow,
    BCol,
    BBadge,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      markerSettingModalOpened: false,
      tableLoading: false,
      selectedAcademicYearId: null,
      selectedTermId: null,
      selectedClassId: null,
      selectedSubjectId: null,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'subject_id',
          label: 'Subject',
        },
        {
          key: 'total_questions',
          label: 'Total Questions',
        },
        {
          key: 'page_type',
          label: 'Page Type',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      reducedClasses: {},
    }
  },
  computed: {
    monitorSettings() {
      return +this.selectedTermId
          * +this.selectedClassId
          * +this.selectedAcademicYearId
    },
    markerSettings() {
      return this.$store.getters['markers/markers']
    },
    classes() {
      if (!this.selectedTerm) {
        return []
      }
      const classes = this.$store.getters['termsClasses/classes']
      if (this.selectedTerm.tag_id) {
        return classes.filter(c => this.selectedTerm.classes.includes(c.id))
      }
      return classes
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
    selectedTerm() {
      return this.terms.find(term => term.ay_term_id === this.selectedTermId)
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    classes(cls) {
      this.reduceCurrentClasses()
    },
    monitorSettings(valid) {
      if (valid) {
        this.fetchMarkerSettings()
        this.$store.commit('assessments/setSettings', {
          selectedAcademicYearId: this.selectedAcademicYearId,
          selectedTermId: this.selectedTermId,
          selectedClassId: this.selectedClassId,
        })
      } else {
        this.$store.commit('assessments/setTerminalAssessments', {
          terminalAssessments: {
            columns: [],
            data: [],
          },
        })
      }
    },
  },
  mounted() {
    this.reduceCurrentClasses()
  },
  methods: {
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = 'blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    downloadSheet(sheet) {
      this.tableLoading = true
      this.$http.get(`markers/download-answer-sheet/${sheet.id}`).then(res => {
        this.downloadFile(res.data, sheet.title)
      })
        .catch(err => {
          logData(err)
          this.showError('Failed to load markers')
        }).finally(() => {
          this.tableLoading = false
        })
    },
    reduceCurrentClasses() {
      this.reducedClasses = this.subjects.reduce((cumulatedValue, currentValue) => {
        // eslint-disable-next-line no-param-reassign
        cumulatedValue[+currentValue.id] = currentValue.name
        return cumulatedValue
      }, {})
    },
    getStatusText(status) {
      let text = 'In Progress'
      // eslint-disable-next-line default-case
      switch (+status) {
        case 1:
          text = 'In Progress'
          break
        case 0:
          text = 'Due'
          break
      }
      return text
    },
    getStatusVariant(status) {
      let variant = 'light-success'
      // eslint-disable-next-line default-case
      switch (+status) {
        case 1:
          variant = 'light-success'
          break
        case 0:
          variant = 'light-danger'
          break
      }
      return variant
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'markerSetting':
          this.markerSettingModalOpened = false
          break
      }
    },
    showMarkerSettingModal() {
      this.markerSettingModalOpened = true
    },
    fetchMarkerSettings() {
      this.tableLoading = true
      this.$http.get(`markers?ay_id=${this.selectedAcademicYearId}&term_id=${this.selectedTerm.id}&class_id=${this.selectedClassId}`).then(res => {
        this.tableLoading = false
        this.$store.commit('markers/setMarkers', res.data)
      })
        .catch(err => {
          this.tableLoading = false
          logData(err)
          this.showError('Failed to load markers')
        })
    },
  },
}
</script>
